import { useCallback, useEffect } from "react";
import { useSidebar } from "./useSidebar";
export const useAddScrollMobileClass = () => {
    // this code is for hiding thirty party icon and stop scroll chain when mobile nav open
    const { active } = useSidebar();
    const switchClass = useCallback(() => {
        var _a, _b;
        const htmlEle = (_a = document === null || document === void 0 ? void 0 : document.getElementsByTagName("html")) === null || _a === void 0 ? void 0 : _a[0];
        const bodyEle = document === null || document === void 0 ? void 0 : document.body;
        const scrollHeight = ((_b = document === null || document === void 0 ? void 0 : document.documentElement) === null || _b === void 0 ? void 0 : _b.scrollTop) || (document === null || document === void 0 ? void 0 : document.body.scrollTop);
        if (active) {
            bodyEle === null || bodyEle === void 0 ? void 0 : bodyEle.setAttribute("class", "pauseScroll");
            htmlEle.setAttribute("style", `top: -${scrollHeight}px; position: fixed; width: 100vw`);
        }
        else {
            bodyEle === null || bodyEle === void 0 ? void 0 : bodyEle.removeAttribute("class");
            const topPx = (htmlEle === null || htmlEle === void 0 ? void 0 : htmlEle.style.top) || "0";
            const top = Math.abs(+topPx.replace("px", ""));
            htmlEle === null || htmlEle === void 0 ? void 0 : htmlEle.removeAttribute("style");
            document === null || document === void 0 ? void 0 : document.documentElement.scrollTo(0, top);
        }
    }, [active]);
    useEffect(() => {
        switchClass();
    }, [switchClass]);
};
