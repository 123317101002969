export const BagIcon = (props) => (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={24} height={24} viewBox="0 0 41 52" xmlSpace="preserve" {...props}>
        <g>
            <defs>
                <rect x="1.48" y="1.58" width="38.61" height="48.69"/>
            </defs>
            <clipPath>
                <use xlinkHref="#SVGID_1_"/>
            </clipPath>
            <path d="M9.49,48.65
		c0.01-0.16,0.02-0.28,0.02-0.39c-0.02-0.44-0.06-0.88-0.06-1.32c-0.03-1.68-0.05-3.36-0.07-5.04c-0.03-1.98-0.05-3.95-0.07-5.93
		c-0.02-2-0.05-4-0.07-6c-0.03-2.87-0.05-5.74-0.07-8.61c-0.01-0.6,0.01-1.19-0.01-1.79c0-0.17-0.03-0.37-0.12-0.51
		c-0.76-1.16-1.53-2.33-2.34-3.46c-0.1-0.14-0.45-0.13-0.68-0.13c-0.06,0-0.17,0.22-0.18,0.35c-0.11,0.92-0.2,1.84-0.3,2.76
		c-0.19,1.78-0.37,3.56-0.56,5.35c-0.15,1.43-0.29,2.85-0.45,4.28c-0.16,1.49-0.33,2.97-0.49,4.46c-0.13,1.19-0.25,2.38-0.37,3.58
		c-0.16,1.51-0.32,3.02-0.48,4.53c-0.14,1.32-0.27,2.64-0.42,3.96c-0.03,0.3,0.02,0.47,0.31,0.62c1.47,0.74,2.94,1.51,4.41,2.26
		C8.14,47.97,8.79,48.3,9.49,48.65 M10.7,49c9.42-1.34,18.72-2.67,28.05-4c-1.13-10.02-2.25-20-3.37-29.99h-0.38
		c-7.47,0-14.94,0-22.41-0.01c-0.29,0-0.43,0.11-0.53,0.35c-0.4,0.91-0.8,1.83-1.21,2.73c-0.3,0.65-0.49,1.31-0.47,2.05
		c0.09,2.51,0.11,5.03,0.16,7.54c0.03,1.93,0.05,3.86,0.07,5.79c0.03,2.45,0.05,4.91,0.07,7.36c0.01,1.45,0.01,2.91,0.01,4.36
		C10.7,46.43,10.7,47.67,10.7,49 M40.09,46.06c-0.96,0.15-1.86,0.28-2.76,0.42c-1.27,0.19-2.53,0.37-3.8,0.54
		c-1.28,0.18-2.56,0.35-3.84,0.53c-1.06,0.15-2.11,0.3-3.17,0.45c-1.27,0.18-2.54,0.36-3.8,0.54c-1.44,0.21-2.88,0.42-4.33,0.63
		c-1.22,0.18-2.44,0.34-3.66,0.51c-1.32,0.19-2.63,0.38-3.94,0.56c-0.47,0.06-0.91,0.03-1.35-0.2c-2.5-1.3-5-2.59-7.51-3.88
		c-0.33-0.17-0.49-0.4-0.44-0.8c0.2-1.78,0.39-3.56,0.57-5.34c0.11-1.1,0.2-2.2,0.31-3.3c0.09-0.9,0.2-1.79,0.3-2.69
		C2.78,33.02,2.89,32,3,30.97c0.16-1.52,0.32-3.04,0.48-4.56c0.13-1.23,0.25-2.46,0.38-3.68c0.11-1.04,0.23-2.08,0.34-3.11
		c0.15-1.52,0.31-3.05,0.45-4.57c0.05-0.58,0.24-0.79,0.82-0.79c0.44,0,0.88,0.01,1.32,0c0.36-0.01,0.6,0.13,0.8,0.43
		c0.6,0.91,1.22,1.82,1.83,2.72c0.07,0.1,0.14,0.19,0.24,0.33c0.08-0.15,0.15-0.27,0.2-0.39c0.44-0.99,0.88-1.97,1.31-2.97
		c0.18-0.42,0.46-0.6,0.91-0.6c7.9,0,15.8,0,23.7,0c0.57,0,0.75,0.2,0.81,0.76c0.12,1.14,0.27,2.28,0.4,3.43
		c0.11,0.97,0.2,1.93,0.31,2.9c0.12,1.13,0.26,2.26,0.38,3.4c0.09,0.8,0.17,1.6,0.26,2.4c0.11,1.01,0.23,2.03,0.34,3.04
		c0.1,0.93,0.2,1.86,0.31,2.79c0.13,1.13,0.27,2.26,0.4,3.39c0.11,0.97,0.2,1.94,0.31,2.9c0.1,0.88,0.21,1.77,0.31,2.65
		c0.12,1.01,0.23,2.03,0.34,3.04C39.99,44.98,40.03,45.49,40.09,46.06"/>
            <path d="M19.7,11.78h-1.32
		c0-0.15,0-0.27,0-0.4c0-2.07,0-4.14,0-6.22c0-1.17,0.47-2.1,1.51-2.64c1.97-1.03,4.03-1.28,6.12-0.41c1.44,0.6,2.26,1.71,2.27,3.32
		c0.02,2,0.02,4,0.04,6c0,0.27-0.06,0.4-0.36,0.37c-0.29-0.03-0.59-0.01-0.92-0.01c-0.01-0.17-0.02-0.33-0.02-0.48
		c0-1.92-0.01-3.83,0.01-5.75c0.01-1.01-0.4-1.73-1.32-2.13c-1.59-0.68-3.21-0.89-4.8,0c-0.8,0.45-1.2,1.17-1.2,2.09
		c0,1.95,0,3.91,0,5.86C19.7,11.5,19.7,11.62,19.7,11.78"/>
            <path d="M18.54,2.61
		c-0.09,0.22-0.22,0.43-0.26,0.66c-0.09,0.54-0.42,0.7-0.93,0.81c-1.43,0.31-2.42,1.42-2.51,2.88c-0.09,1.46-0.06,2.92-0.08,4.39
		c0,0.14,0,0.28,0,0.44h-1.23c-0.01-0.11-0.02-0.21-0.02-0.31c0-1.42-0.01-2.83,0-4.25c0.02-2.32,1.35-3.96,3.62-4.46
		C17.57,2.66,18,2.6,18.43,2.53C18.47,2.56,18.51,2.58,18.54,2.61"/>
            <path d="M20.76,4.77
		c0.21-0.3,0.4-0.55,0.57-0.83c0.13-0.21,0.26-0.22,0.46-0.09c0.87,0.57,1.34,1.4,1.55,2.39c0.07,0.33,0.11,0.68,0.11,1.02
		c0.01,1.39,0,2.79-0.01,4.18c0,0.1-0.02,0.21-0.03,0.33h-1.17c-0.01-0.13-0.02-0.27-0.02-0.41c0-1.22-0.02-2.43,0.01-3.64
		c0.02-1.2-0.31-2.21-1.36-2.89C20.83,4.81,20.79,4.79,20.76,4.77"/>
            <path d="M27.11,28.34
		c-0.05-0.46-0.1-0.88-0.5-1.09c-0.15-0.08-0.4-0.16-0.51-0.09c-0.19,0.11-0.44,0.35-0.44,0.53c-0.01,0.95,0.04,1.9,0.08,2.85
		c0.03,0.57,0.08,1.14,0.11,1.71c0.01,0.25,0.06,0.53,0.32,0.57c0.22,0.03,0.49-0.04,0.68-0.15c0.33-0.2,0.42-0.52,0.4-0.92
		c-0.04-0.76-0.12-0.9,0.78-0.94c0.2-0.01,0.4,0,0.6,0.02c0.25,0.03,0.37,0.2,0.38,0.45c0.03,0.56,0.06,1.12,0.07,1.68
		c0.01,1.05-0.46,1.57-1.51,1.64c-0.65,0.04-1.31,0.07-1.96,0.08c-0.94,0.01-1.52-0.5-1.57-1.43c-0.11-2.08-0.21-4.16-0.3-6.24
		c-0.04-1.03,0.4-1.48,1.44-1.52c0.74-0.03,1.48-0.07,2.21-0.06c0.95,0.01,1.62,0.93,1.31,1.8c-0.08,0.23-0.3,0.46-0.51,0.6
		C27.87,28.03,27.5,28.16,27.11,28.34"/>
            <path d="M18.74,31.81
		c0.04,0.4,0.05,0.73,0.1,1.06c0.05,0.34,0.29,0.47,0.61,0.48c0.47,0.03,0.75-0.1,0.85-0.42c0.06-0.19,0.08-0.4,0.07-0.6
		c-0.02-2.02-0.05-4.05-0.08-6.07c-0.01-0.69,0.1-0.8,0.79-0.84c0.15-0.01,0.31-0.01,0.47-0.01c0.63,0,0.77,0.13,0.78,0.77
		c0.01,0.51,0,1.02,0,1.54c0.03,1.77,0.06,3.55,0.08,5.32c0,0.25,0.01,0.5-0.03,0.75c-0.16,0.9-0.76,1.37-1.67,1.39
		c-0.66,0.01-1.33,0.06-2,0.07c-0.89,0.01-1.61-0.53-1.86-1.38c-0.15-0.51-0.01-0.95,0.44-1.25C17.75,32.34,18.23,32.1,18.74,31.81"/>
        </g>
    </svg>);
