import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { Container, Link, MenuPopup, NextImage } from "@jog/react-components";
import { newHeaderEvent } from "@jog/shared";
import classNames from "classnames";
import { useState } from "react";
const RichContent = ({ htmlStr }) => (<div className="mb-0" dangerouslySetInnerHTML={{ __html: htmlStr }}/>);
const CategoryTag = ({ label, color, bgColor }) => (<span className="ml-2 flex items-center justify-center bg-red-500 px-1 text-center !text-0.85 text-white" style={{ color, backgroundColor: bgColor }}>
        {label}
    </span>);
export const DesktopNavMenu = () => {
    var _a, _b;
    const [activeMenu, setActiveMenu] = useState("");
    const layout = useLayout();
    const { menu } = newHeaderEvent();
    return (<div className="flex h-full w-full items-center justify-center text-sm text-gray-base">
            {(_b = (_a = layout === null || layout === void 0 ? void 0 : layout.nav) === null || _a === void 0 ? void 0 : _a.desktop) === null || _b === void 0 ? void 0 : _b.map((navItem) => {
            var _a;
            return (<div className="h-full cursor-pointer" key={navItem.id} onMouseEnter={() => setActiveMenu(navItem.id)} onMouseLeave={() => setActiveMenu("")}>
                    {((_a = navItem.categoryName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "winkels" && (<Link className="relative flex h-full cursor-pointer items-center justify-center px-4 text-center font-semibold uppercase text-gray-base" onClick={menu} to={navItem.categoryUrl}>
                            <div style={{ color: navItem.categoryTextColor || "" }}>
                                {activeMenu === navItem.id && !!navItem.subCategoryItems.length && (<span className="absolute left-[40%] -bottom-2 z-20 h-4 w-4 rotate-45 border border-b-0 border-r-0 border-gray-light bg-white"/>)}
                                <RichContent htmlStr={navItem.categoryName}/>
                            </div>
                        </Link>)}
                    <MenuPopup isOpen={activeMenu === navItem.id && !!navItem.subCategoryItems.length}>
                        <Container className="flex p-5">
                            {navItem.subCategoryItems.map((subCategoryItem, index) => (<div className="w-1/5" key={index}>
                                    <Link onClick={() => {
                        setActiveMenu("");
                        menu();
                    }} to={subCategoryItem.subCategoryUrl}>
                                        <div className="flex items-center !leading-5">
                                            <div className={classNames("font-medium", subCategoryItem.subCategoryUrl
                        ? "cursor-pointer hover:underline"
                        : "cursor-default")} style={{ color: subCategoryItem.subCategoryTextColor || "" }}>
                                                <RichContent htmlStr={subCategoryItem.subCategoryName}/>
                                            </div>
                                            {!!subCategoryItem.subLabelText && (<CategoryTag label={subCategoryItem.subLabelText} color={subCategoryItem.subLabelTextColor || ""} bgColor={subCategoryItem.subLabelBgColor || ""}/>)}
                                        </div>
                                    </Link>
                                    <div className="mt-2.5">
                                        {!!subCategoryItem.thirdCategoryItems.length &&
                        subCategoryItem.thirdCategoryItems.map(({ thirdCategoryUrl, thirdCategoryTextColor, thirdCategoryName, thirdLabelText, thirdLabelTextColor, thirdLabelBgColor, }, index) => (<div className="w-full py-1" key={index}>
                                                        <Link className="text-base !leading-normal" onClick={() => {
                                setActiveMenu("");
                                menu();
                            }} to={thirdCategoryUrl}>
                                                            <div className="flex items-center">
                                                                <div className="hover:!text-red-main" style={{ color: thirdCategoryTextColor || "" }}>
                                                                    <RichContent htmlStr={thirdCategoryName}/>
                                                                </div>
                                                                {!!thirdLabelText && (<CategoryTag label={thirdLabelText} color={thirdLabelTextColor || ""} bgColor={thirdLabelBgColor || ""}/>)}
                                                            </div>
                                                        </Link>
                                                    </div>))}
                                    </div>
                                </div>))}
                            {(navItem === null || navItem === void 0 ? void 0 : navItem.bannerImage) && (<div className="w-1/5">
                                    <Link onClick={() => {
                        setActiveMenu("");
                        menu();
                    }} to={navItem.bannerUrl}>
                                        <NextImage width={300} height={300} quality={100} src={navItem.bannerImage} alt="Category navigation banner"/>
                                    </Link>
                                </div>)}
                        </Container>
                    </MenuPopup>
                </div>);
        })}
        </div>);
};
