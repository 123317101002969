import { useCallback, useMemo, useState } from "react";
import { useAddScrollMobileClass } from "./useAddScrollMobileClass";
import { useSidebar } from "./useSidebar";
export const useMobileNav = () => {
    const { toggle } = useSidebar();
    const [subMenus, setSubMenus] = useState([]);
    const [thirdMenus, setThirdMenus] = useState([]);
    const [activeMainMenu, setActiveMainMenu] = useState("");
    const [activeSubMenu, setActiveSubMenu] = useState("");
    const mainMenuShow = useMemo(() => !subMenus.length && !thirdMenus.length, [subMenus.length, thirdMenus.length]);
    const subMenuShow = useMemo(() => subMenus.length && !thirdMenus.length, [subMenus.length, thirdMenus.length]);
    const hideMenu = useCallback(() => {
        setSubMenus([]);
        setActiveMainMenu("");
        setThirdMenus([]);
        setActiveSubMenu("");
        toggle();
    }, [toggle]);
    useAddScrollMobileClass();
    return {
        mainMenuShow,
        subMenuShow,
        hideMenu,
        subMenus,
        setSubMenus,
        thirdMenus,
        setThirdMenus,
        activeMainMenu,
        setActiveMainMenu,
        activeSubMenu,
        setActiveSubMenu,
    };
};
