import { asImageSrc, isFilled } from "@prismicio/helpers";
import { isInteger } from "lodash-es";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { Arrow, NextImage } from "../../components";
import { useI18n, useValidateEmail } from "../../hooks";
export const ReturnLoginBase = ({ Button, Container, Input, RichText, AlertBox, LogoLoader, onSubmitGuestLogin, }) => {
    function ReturnLoginContent({ prismicContent }) {
        var _a, _b, _c;
        const router = useRouter();
        const { $t } = useI18n();
        const [isOrderNumberError, setIsOrderNumberError] = useState(false);
        const [isEmailError, setIsEmailError] = useState(false);
        const [isError, setError] = useState(false);
        const [isButtonDisabled, setIsButtonDisabled] = useState(true);
        const [userEmail, setUserEmail] = useState("");
        const [orderNumber, setOrderNumber] = useState("");
        const [loading, setLoading] = useState(false);
        const { validateEmail } = useValidateEmail();
        const validateUserEmail = useCallback((event) => {
            const value = String(event.target.value);
            setIsEmailError(!validateEmail(value));
            setIsButtonDisabled(!validateEmail(value) || !orderNumber);
            setUserEmail(value);
        }, [validateEmail, orderNumber]);
        const validateOrderNumber = useCallback((event) => {
            const value = event.target.value;
            setIsOrderNumberError(!isInteger(Number(value)));
            setIsButtonDisabled(!validateEmail(userEmail) || !value);
            setOrderNumber(value);
        }, [validateEmail, userEmail]);
        const handleLoginGuest = useCallback(async () => {
            var _a;
            try {
                setLoading(true);
                const orderDetail = await onSubmitGuestLogin(orderNumber, userEmail);
                if ((_a = orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.body) === null || _a === void 0 ? void 0 : _a.order) {
                    setError(false);
                    await router.push(`/order/${orderNumber}?email=${encodeURIComponent(userEmail)}`);
                }
                else {
                    setError(true);
                }
                setLoading(false);
            }
            catch (error) {
                console.error(error);
                setError(true);
                setLoading(false);
            }
        }, [orderNumber, router, userEmail]);
        return (<Container>
                {loading && <LogoLoader />}
                {isError && <AlertBox isError text={$t("Return login error")}/>}
                <div className="mt-5 mb-32 grid grid-cols-1 bg-white md:grid-cols-2">
                    <div className="p-6 md:p-8">
                        {isFilled.richText(prismicContent.return_login_title) && (<div className="mb-2">
                                <RichText field={prismicContent.return_login_title}/>
                            </div>)}
                        {isFilled.richText(prismicContent.return_login_content) && (<div className="my-5">
                                <RichText field={prismicContent.return_login_content}/>
                            </div>)}
                        <form onSubmit={(event) => event.preventDefault()}>
                            <div className="mb-6">
                                <div>
                                    <label htmlFor="orderNumber">{$t("orderNumber")}</label>
                                </div>
                                <div className="grid md:grid-cols-4">
                                    <div className="md:col-span-2">
                                        <Input className="!h-9 w-full !text-left" id="orderNumber" name="orderNumber" type="text" onChange={validateOrderNumber}/>
                                    </div>
                                </div>
                                {isOrderNumberError && orderNumber && (<div className="absolute text-13">{$t("Invalid orderNumber")}</div>)}
                            </div>
                            <div className="mb-6">
                                <div>
                                    <label htmlFor="email">{$t("E-mail address")}</label>
                                </div>

                                <div className="grid md:grid-cols-4">
                                    <div className="md:col-span-2">
                                        <Input className="!h-9 w-full !text-left" id="email" name="email" type="email" onChange={validateUserEmail}/>
                                    </div>
                                </div>
                                {isEmailError && userEmail && (<div className="absolute text-13">{$t("Invalid email address")}</div>)}
                            </div>

                            <div className="mt-8 mb-5">
                                <Button className="w-auto !px-5" bgColorHover={prismicContent.return_login_button_hover_color || "none"} bgColor={prismicContent.return_login_button_bg_color || "#3F4248"} color={prismicContent.return_login_button_text_color || "#ffffff"} disabled={isButtonDisabled} onClick={handleLoginGuest}>
                                    <div className="flex items-center">
                                        <span className="mr-2 font-medium">
                                            {prismicContent.return_login_button_text}
                                        </span>
                                        <Arrow color={prismicContent.return_login_button_text_color || "#ffffff"}/>
                                    </div>
                                </Button>
                            </div>
                        </form>
                        {isFilled.richText(prismicContent.return_login_bottom_text) && (<div className="mt-5">
                                <RichText field={prismicContent.return_login_bottom_text}/>
                            </div>)}
                    </div>
                    {isFilled.image(prismicContent.return_login_banner) && (<div>
                            <NextImage className="h-full max-h-[500px] w-full object-cover" width={((_a = prismicContent.return_login_banner.dimensions) === null || _a === void 0 ? void 0 : _a.width) || 570} height={((_b = prismicContent.return_login_banner.dimensions) === null || _b === void 0 ? void 0 : _b.height) || 440} src={asImageSrc(prismicContent.return_login_banner)} alt={((_c = prismicContent.return_login_banner) === null || _c === void 0 ? void 0 : _c.alt) || ""} quality={100}/>
                        </div>)}
                </div>
            </Container>);
    }
    return ReturnLoginContent;
};
