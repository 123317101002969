import { AlertBox } from "@/components/AlertBox/AlertBox";
import { Button } from "@/components/Button/Button";
import { Container } from "@/components/Container/Container";
import { Input } from "@/components/InputText/InputText";
import { LogoLoader } from "@/components/LogoLoader/LogoLoader";
import { JCRichText } from "@/components/RichText/RichText";
import { ReturnLoginBase } from "@jog/react-components";
import { returnOrderDetail } from "@jog/shared";
import React from "react";
export const ReturnLoginContent = React.memo(ReturnLoginBase({
    Button,
    Container,
    Input,
    RichText: JCRichText,
    AlertBox,
    LogoLoader,
    onSubmitGuestLogin: returnOrderDetail,
}));
