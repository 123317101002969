import { ErrorPage } from "@/components/ErrorPage/ErrorPage";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { theme } from "@/layout/theme";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { fetchPopup } from "@/lib/prismicio/popup";
import { useEmailValidation } from "@/lib/prismicio/useEmailValidation";
import { useWishlist } from "@/lib/store/wishlist";
import { AddedProductProvider, I18nCtx, LayoutErrorBoundary, PopupProvider, SidebarProvider, ValidateEmail, useAdpage, useTwProfileKey, } from "@jog/react-components";
import { gtmAddUserId, gtmAdpageUserData, gtmPageTracking, localStorage } from "@jog/shared";
import { ThemeProvider, withTheme } from "@jog/theming";
import { AlertBox, Modal, TopBanner } from "components";
import { CampaignPopup } from "containers";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
const reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY;
const LayoutError = () => <ErrorPage />;
const Layout = ({ children, gtmTypeOfPage, isCartPage, isCheckoutPage, newsletter = true, isTopBannerVisible = true, }) => {
    const router = useRouter();
    useTwProfileKey();
    useEffect(() => {
        router.isReady && gtmPageTracking(gtmTypeOfPage);
    }, [gtmTypeOfPage, router]);
    const adpage = useAdpage();
    useEffect(() => {
        router.isReady && (adpage === null || adpage === void 0 ? void 0 : adpage.marketing) && (adpage === null || adpage === void 0 ? void 0 : adpage.device) && gtmAdpageUserData(adpage, gtmTypeOfPage);
    }, [adpage, gtmTypeOfPage, router.asPath, router.isReady]);
    useEffect(() => {
        const userId = localStorage.getItem("user-id");
        gtmAddUserId(userId);
    }, []);
    const layout = useLayout();
    const validateEmailList = useEmailValidation();
    const wishlistError = useWishlist.use.error();
    const wishlist = useWishlist.use.initWishlist();
    useEffect(() => {
        wishlist();
    }, [wishlist]);
    return (<AddedProductProvider closeTime={(layout === null || layout === void 0 ? void 0 : layout.layout.cartPopupShowTime) || 5000}>
            <PopupProvider fetch={fetchPopup}>
                <I18nCtx.Provider value={{ translations: (layout === null || layout === void 0 ? void 0 : layout.translations) || {} }}>
                    <ValidateEmail.Provider value={validateEmailList}>
                        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language={process.env.NEXT_PUBLIC_LANG}>
                            <ThemeProvider theme={theme}>
                                <SidebarProvider>
                                    <Header isCartPage={isCartPage} isCheckoutPage={isCheckoutPage}/>
                                </SidebarProvider>

                                {isTopBannerVisible && <TopBanner />}

                                {/* Main section */}
                                <LayoutErrorBoundary Fallback={LayoutError}>{children}</LayoutErrorBoundary>

                                {/* Bottom */}
                                <Footer newsletter={newsletter}/>
                                <CampaignPopup />

                                {wishlistError && (<Modal>
                                        <AlertBox isError text={wishlistError.message}/>
                                    </Modal>)}
                            </ThemeProvider>
                        </GoogleReCaptchaProvider>
                    </ValidateEmail.Provider>
                </I18nCtx.Provider>
            </PopupProvider>
        </AddedProductProvider>);
};
export default withTheme(observer(Layout));
