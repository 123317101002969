import { ContentLoader, useI18n } from "@jog/react-components";
import React from "react";
import JcFriendCardWrapper from "./JcFriendCardWrapper";
const JcFriendCard = ({ userPoints, loyaltyCardNumber, userEmail, availableCredit, }) => {
    const { $t } = useI18n();
    return (<>
            <JcFriendCardWrapper>
                <div className="points-overview">
                    <h3>{$t("Points")}</h3>

                    {userPoints ? <p>{userPoints}</p> : <ContentLoader height="40px" width="200px"/>}

                    <hr />
                    {availableCredit && (<>
                            <h3>{$t("Credit")}</h3>
                            <p>€{availableCredit}</p>
                        </>)}
                </div>
                <div className="profile-overview">
                    <h2>{$t("JC Friends card")}</h2>
                    <p>{userEmail}</p>
                    <p>
                        <span className="bold">{$t("Card number")}:</span> {loyaltyCardNumber}
                    </p>
                </div>
            </JcFriendCardWrapper>
        </>);
};
export default React.memo(JcFriendCard);
