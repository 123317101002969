import { styled } from "@jog/theming";
import classNames from "classnames";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col } from "react-bootstrap";
const StyledField = styled.div `
    input:is([type="date"], [type="time"], [type="datetime-local"], [type="month"], [type="week"]) {
        color: #000;
        background: #fff;
        -webkit-appearance: none;
    }

    input::-webkit-date-and-time-value {
        text-align: start;
    }
`;
const FormikInputFieldFC = ({ label, fieldName, type, id, smLeft, smRight, leftOffset, isInputDisabled, inputBlur, placeholder, inputMode = "", autocomplete = "", css = {}, labelCls, inputCls, }) => {
    return (<>
            <Col sm={{ span: +(smLeft || 4), offset: +(leftOffset || 0) }}>
                <label htmlFor={id} className={classNames("mt-2.5 cursor-pointer self-center", labelCls)}>
                    {label}
                </label>
            </Col>
            <Col sm={+(smRight || 8)}>
                <StyledField>
                    <Field type={type || "text"} name={fieldName} id={id} disabled={isInputDisabled} onBlur={() => (inputBlur ? inputBlur() : "")} placeholder={placeholder} inputMode={inputMode} autoComplete={autocomplete} css={css} className={classNames("h-11.5 w-full border border-gray-light py-2.75 px-3 align-top outline-none focus:border-gray-shuttle disabled:border-gray-bg", inputCls)}/>
                </StyledField>
                <ErrorMessage name={fieldName} component="div" className="Error"/>
            </Col>
        </>);
};
export const FormikInputField = React.memo(FormikInputFieldFC);
