import { Link, Money, NextImage, useI18n, useLineItemLocalized, useLineItemPrice } from "@jog/react-components";
const Price = ({ finalPrice, firstPrice }) => {
    if (finalPrice) {
        return (<p className="text-right text-xs font-medium tracking-0.03 text-red-sale">
                <Money centAmount={finalPrice}/>
            </p>);
    }
    return (<p className="text-right text-xs font-medium tracking-0.03 text-gray-base">
            <Money centAmount={firstPrice}/>
        </p>);
};
export const AddedItem = ({ item }) => {
    const { image, length, size } = item;
    const { $t } = useI18n();
    const { link, name, promotion } = useLineItemLocalized(item);
    const { finalPrice, firstPrice } = useLineItemPrice(item);
    return (<li className="grid grid-cols-[77px,auto] gap-3 border-b border-b-gray-light py-6 last-of-type:border-b-0 last-of-type:pb-0">
            <div>
                <Link href={link}>
                    <NextImage blur={false} height="113" width="77" src={image} alt="Cart item image" className="img-fluid w-full"/>
                </Link>
            </div>
            <div className="flex flex-col justify-between">
                <div>
                    <Link href={link}>
                        <h4 className="text-xs font-medium uppercase leading-tight tracking-0.03 text-grey-default">
                            {name}
                        </h4>
                    </Link>

                    {promotion && (<div className="mt-1 inline-block bg-grey-default px-3 py-1 text-xs text-gray-cream">
                            {promotion}
                        </div>)}
                    <div className="mt-1.5 text-xs leading-tight tracking-0.03 text-grey-default">
                        {length
            ? `${$t("Size: {size}/{length}", { size, length })}`
            : `${$t("Size: {size}", { size })}`}
                    </div>
                </div>
                <div>
                    <Price finalPrice={finalPrice} firstPrice={firstPrice}/>
                </div>
            </div>
        </li>);
};
