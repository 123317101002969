import { Container } from "@/components";
import { JCRichText } from "@/components/RichText/RichText";
import { useI18n } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import JcPointsForm from "./JcPointsForm";
export const JcFriendsContent = ({ data }) => {
    const { $t } = useI18n();
    return (<Container className="mb-17.5 sm:max-xl:min-w-full">
            <div>
                <div className="bg-white px-8 py-8 pt-6 text-base">
                    <div>
                        <h1 className="mt-3 mb-7.5 text-22 uppercase text-gray-base">
                            {$t("Check your point balance")}
                        </h1>
                        <div className="mb-7">
                            {isFilled.richText(data.explain_text) && (<JCRichText field={data.explain_text} linkClass="text-black underline hover:text-[#ee582a]"/>)}
                        </div>
                        <div className="mb-7">
                            {isFilled.richText(data.card_digits_help_text) && (<JCRichText field={data.card_digits_help_text} linkClass="text-black underline hover:text-[#ee582a]"/>)}
                        </div>
                        <JcPointsForm />
                        <div className="my-7">
                            {isFilled.richText(data.points_balance_more_info) && (<JCRichText field={data.points_balance_more_info} linkClass="text-black underline hover:text-[#ee582a]"/>)}
                        </div>
                    </div>
                </div>
            </div>
        </Container>);
};
