import { useEffect, useMemo, useState } from "react";
export const useNoticeBar = (length) => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const timer = setTimeout(() => {
            const index = activeIndex >= length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(index);
        }, 3000);
        return () => clearTimeout(timer);
    }, [activeIndex, length]);
    return useMemo(() => `-${100 * activeIndex}%`, [activeIndex]);
};
