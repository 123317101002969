import { Trash as Remove } from "@/components/Icon/Trash";
import { useStores } from "@/stores";
import { Link, Money, NextImage, useI18n, useLineItemLocalized, useLineItemPrice, usePending, } from "@jog/react-components";
import { gtmRemoveFromCart } from "@jog/shared";
const Price = ({ finalPrice, firstPrice }) => {
    if (finalPrice) {
        return (<p className="text-right text-sm font-medium leading-tight tracking-0.03 text-red-sale">
                <Money centAmount={finalPrice}/>
            </p>);
    }
    return (<p className="text-right text-sm font-medium leading-tight tracking-0.03 text-grey-default">
            <Money centAmount={firstPrice}/>
        </p>);
};
export const CartItem = ({ item }) => {
    const { cartStore } = useStores();
    const { setPending } = usePending();
    const { image, length, size, quantity } = item;
    const { $t } = useI18n();
    const { link, name, promotion } = useLineItemLocalized(item);
    const { finalPrice, firstPrice } = useLineItemPrice(item);
    const deleteProduct = async () => {
        gtmRemoveFromCart(item, quantity);
        setPending(true);
        await cartStore.deleteCartProduct(item.id, quantity).finally(() => {
            setPending(false);
        });
    };
    return (<li className="grid grid-cols-[77px,auto] gap-2 border-b border-b-gray-light py-3 last-of-type:border-b-0 last-of-type:pb-0">
            <div>
                <Link href={link}>
                    <NextImage blur={false} height="113" width="77" src={image} alt="Cart item image" className="img-fluid w-full"/>
                </Link>
            </div>
            <div className="flex flex-col justify-between">
                <div>
                    <div className="relative">
                        <Link href={link} className="flex w-[180px]">
                            <h4 className="text-xs font-medium uppercase tracking-0.03 text-grey-default">{name}</h4>
                        </Link>
                        <button className="absolute right-0 top-0 flex h-6 w-6 items-center justify-center text-[#adadad]" onClick={deleteProduct}>
                            <Remove width={18} height={22}/>
                        </button>
                    </div>
                    {promotion && (<div className="mt-1.5 inline-block bg-grey-default px-4 py-1 text-xs text-gray-cream">
                            {promotion}
                        </div>)}
                    <div className="mt-1.5 text-xs tracking-0.03 text-grey-default">
                        {length
            ? `${$t("Size: {size}/{length}", { size, length })}`
            : `${$t("Size: {size}", { size })}`}
                    </div>
                </div>
                <div className="flex justify-end">
                    <Price finalPrice={finalPrice} firstPrice={firstPrice}/>
                </div>
            </div>
        </li>);
};
