import { styled } from "@jog/theming";
const JcFriendCardWrapper = styled.div `
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: ${(props) => props.theme.padding.large};
    padding-left: 0;
    @media screen and (max-width: 424px) {
        flex-flow: column nowrap;
        padding: ${(props) => props.theme.padding.large} 0;
    }
    .points-overview {
        background-color: #809a7b;
        color: white;
        padding: ${(props) => props.theme.padding.large};
        margin-right: ${(props) => props.theme.padding.large};
        text-align: center;
        @media screen and (max-width: 424px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    .profile-overview {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        p {
            margin-bottom: 0;
        }
        @media screen and (max-width: 580px) {
            p {
                font-size: 12px;
            }
        }
        @media screen and (max-width: 424px) {
            text-align: center;
        }
    }
`;
export default JcFriendCardWrapper;
