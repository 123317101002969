import { ContentLoader, useI18n } from "@jog/react-components";
import { isObjectEmpty } from "@jog/shared";
import { Section } from "components";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStores } from "stores";
import JcFriendCard from "../JcFriendCard/JcFriendCard";
const AccountOverview = () => {
    var _a, _b, _c, _d, _e, _f;
    const { $t } = useI18n();
    const { userStore, voucherStore } = useStores();
    const userData = userStore.userData;
    const [name] = useState(((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.name) || localStorage.getItem("name"));
    const retrieveUserVouchers = useCallback(async () => {
        var _a, _b;
        if (isObjectEmpty(voucherStore.userVouchers) && ((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.loyaltycardnumber)) {
            await voucherStore.getUserVouchers((_b = userStore.userData) === null || _b === void 0 ? void 0 : _b.loyaltycardnumber);
        }
    }, [(_b = userStore.userData) === null || _b === void 0 ? void 0 : _b.loyaltycardnumber, voucherStore]);
    useEffect(() => {
        retrieveUserVouchers();
    }, [retrieveUserVouchers]);
    const calculateNonPercentageVouchersAmount = useMemo(() => {
        var _a;
        return (_a = voucherStore.userVouchers) === null || _a === void 0 ? void 0 : _a.vouchers.reduce((pre, cur) => (cur.voucherType !== "PERCENTAGE" ? pre + cur.value : pre), 0);
    }, [(_c = voucherStore.userVouchers) === null || _c === void 0 ? void 0 : _c.vouchers]);
    return (<>
            <Section>
                {name ? (<h1 className="mb-5 flex items-center text-3.5xl uppercase leading-normal text-gray-base">
                        {$t("Hello")} {name}!
                    </h1>) : (<ContentLoader height="40px" width="200px"/>)}
                <div className="home-description">
                    {$t("Here you can change your order history, address book and personal information.")}
                </div>

                {(userData === null || userData === void 0 ? void 0 : userData.loyaltycardnumber) && (<>
                        <JcFriendCard userPoints={(_d = voucherStore.userVouchers) === null || _d === void 0 ? void 0 : _d.points} loyaltyCardNumber={userData === null || userData === void 0 ? void 0 : userData.loyaltycardnumber} userEmail={userData.email} availableCredit={((_f = (_e = voucherStore.userVouchers) === null || _e === void 0 ? void 0 : _e.vouchers) === null || _f === void 0 ? void 0 : _f.length) > 0
                ? calculateNonPercentageVouchersAmount / 100
                : ""}/>
                        <hr />
                        <div className="jc-friend-disclaimer">
                            <p>
                                {$t("As a JC Friend you earn points with every purchase. You save € 5 off every 100 points.")}
                            </p>
                        </div>
                    </>)}
            </Section>
        </>);
};
export default observer(AccountOverview);
