// TODO delete this file: react-bootstrap  10px = 10px
export const theme = {
    color: {
        // ===================== Tailwind Css
        mainGreen: "#809A7B",
        iconStroke: "#5b606b",
        iconLabel: "#484b51",
        lightGreen: "#e3f0bf",
        draculaGray: "#212529",
        mainGray: "#444a55",
        shuttleGray: "#5C616B",
        darkGray: "#737780",
        gray: "#a1a4aa",
        silverGray: "#bfbfbf",
        mediumGray: "#d0d2d4",
        lightGray: "#eeeeee",
        backgroundGray: "#f7f7f7",
        monzaRed: "#E30613",
        mainRed: "#ff0000",
        errorRed: "#a54445",
        pink: "#ea5175",
        salmonPink: "#f1dede",
        darkBlue: "#153e6e",
        taraBlue: "#074054",
        whiteBlue: "#2196f3",
        yellow: "#ffcf0c",
        lightYellow: "#fcf8e3",
        mcKenzie: "#8a6d3b",
        primaryHover: "#ee582a",
        lightOrange: "#ef9e77",
        alertBoxGray: "#434955",
        yellowGreen: "#bed776",
        pastelOlive: "#f4f8ea",
        anthracite: "#4e4e4e",
        black: "#000000",
        white: "#ffffff", // black
    },
    zIndex: {
        fixed: 7,
        headerWrapper: 2,
        navWrapper: 7,
        snackbar: 3,
        dropDownBox: 4,
        modal: 9,
        logoLoader: 10,
        brandLogo: 2,
    },
    padding: {
        small: "5px",
        standard: "10px",
        large: "20px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        pd1: "3px",
        pd2: "5px",
        pd3: "10px",
        pd4: "15px",
        pd5: "20px",
        pd6: "30px",
    },
    spacing: {
        // Note: we don't have a Design System yet so this is arbitrary
        sp1: "3px",
        sp2: "5px",
        sp3: "10px",
        sp4: "15px",
        sp5: "20px",
        sp6: "30px",
    },
    fontFamily: {
        icomoonJC: "icomoon-jc",
        primary: "AvenirNextLTW01",
    },
    fontSize: {
        paragraph: "8px",
        label: "11px",
        itemLabel: "11px",
        mainHeader: "20px",
        secondaryHeader: "9px",
        itemHeader: "15px",
        priceElement: "16px",
        modalParagraph: "14px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        fs1: "8px",
        fs2: "9px",
        fs3: "11px",
        fs4: "14px",
        fs5: "15px",
        fs6: "16px",
        fs7: "20px",
    },
    // This property is also used in util/responsiveImages.js. The order of the values here is important! sm -> xl
    media: {
        sm: "screen and (max-width: 575px)",
        md: "screen and (max-width: 767px)",
        lg: "screen and (max-width: 991px)",
        xl: "screen and (max-width: 1199px)",
        xxl: "screen and (max-width: 1399px)", //  xxl = 1200 - 1399
        // xxxl: 'screen and (max-width: inf); // xxxl = 1400 -  inf - illustrative only, use 'theme.mediaFrom.xxl' to target this size
    },
    // This follows Bootstrap breakpoints
    mediaFrom: {
        // xs: 'screen and (min-width: 0px)'   //  xs =    0 -  575 - illustrative only, use default styles (no media queries) to target this size
        sm: "screen and (min-width: 576px)",
        md: "screen and (min-width: 768px)",
        lg: "screen and (min-width: 992px)",
        xl: "screen and (min-width: 1200px)",
        xxl: "screen and (min-width: 1400px)", // xxl = 1400 -  inf
    },
};
