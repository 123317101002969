import { ArrowButton } from "@/components/ArrowButton/ArrowButton";
import { ArrowLink } from "@/components/ArrowLink/ArrowLink";
import { Container } from "@/components/Container/Container";
import { JCRichText } from "@/components/RichText/RichText";
import ErrorPageContent from "@/lib/prismicio/prismic-error-page.json";
import { NextImage } from "@jog/react-components";
import { Col, Row } from "react-bootstrap";
export const ErrorPage = () => {
    const content = ErrorPageContent;
    return (<Container className="mb-28 mt-4 sm:max-xl:min-w-full">
            <Row css={{ "@media (max-width: 1000px)": { padding: "0 25px" } }}>
                <Col sm="12" lg="7" xl="7">
                    <h1 className="my-5.5 font-primary text-22 font-normal uppercase text-gray-base">
                        {content.title}
                    </h1>
                    <h2 className="mb-5.5 font-primary text-base font-normal text-gray-base">{content.subtitle}</h2>
                    <div className="my-5 text-base">
                        {content.listDescription && <JCRichText field={content.listDescription}/>}
                    </div>
                    {content.links.map((link, index) => (<ArrowLink key={index} marginBot="10px" text={link.text} href={link.href}/>))}
                </Col>
                <Col sm="12" lg="5" xl="5" css={{ padding: 0 }}>
                    {content.image ? (<NextImage blur={false} src={content.image.src} alt={content.image.alt} className="h-auto max-w-full" width={content.image.width} height={content.image.height}/>) : null}
                </Col>
            </Row>
            <Row css={{ "@media (max-width: 1000px)": { padding: "0 25px" } }}>
                <Col xl="12" lg="12" md="12">
                    <a href="/">
                        <ArrowButton margin="32px 0" className="text-center">
                            {content.button}
                        </ArrowButton>
                    </a>
                </Col>
            </Row>
        </Container>);
};
