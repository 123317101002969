import { localStorage, setPopupModalDisplayTime, shouldShowPopupModal } from "@jog/shared";
import { asImageSrc } from "@prismicio/helpers";
import { useEffect, useMemo, useState } from "react";
import { NextImage } from "../../components";
import { usePopup } from "../../hooks";
export const useCampaignPopup = () => {
    const { popup } = usePopup();
    const [showModalAfterDelay, setShowModalAfterDelay] = useState(false);
    const [showThankyouMessage, setShowThankyouMessage] = useState(false);
    const isBlackListed = useMemo(() => {
        const blacklistedPages = (popup === null || popup === void 0 ? void 0 : popup.campaignBlacklist) || [];
        // e.g. "cart"
        const currentPathName = (typeof window !== "undefined" && window.location.pathname.slice(1).replace(/\/+$/, "")) || "";
        return blacklistedPages.includes(currentPathName);
    }, [popup]);
    useEffect(() => {
        const showPopupLocalStorage = localStorage.getItem("showPopupAfterRefresh");
        if (!showPopupLocalStorage && !isBlackListed) {
            const delayInSeconds = (popup === null || popup === void 0 ? void 0 : popup.campaignPopupDelay) ? popup.campaignPopupDelay * 1000 : 20000;
            const timer = setTimeout(() => {
                setShowModalAfterDelay(true);
                localStorage.setItem("showPopupAfterRefresh", true);
            }, delayInSeconds);
            return () => clearTimeout(timer);
        }
        else {
            setShowModalAfterDelay(true);
        }
    }, [isBlackListed, popup]);
    const [showPopupModal, setShowPopupModal] = useState(false);
    useEffect(() => {
        // Check if popup modal should be displayed
        setShowPopupModal(shouldShowPopupModal());
    }, []);
    const togglePopupModal = () => {
        setShowPopupModal((v) => !v);
        setPopupModalDisplayTime();
        localStorage.setItem("showPopupAfterRefresh", false);
    };
    return {
        togglePopupModal,
        showThankyouMessage,
        setShowThankyouMessage,
        display: useMemo(() => showPopupModal && (popup === null || popup === void 0 ? void 0 : popup.activatePopupCampaign) && !isBlackListed && showModalAfterDelay, [isBlackListed, popup === null || popup === void 0 ? void 0 : popup.activatePopupCampaign, showModalAfterDelay, showPopupModal]),
    };
};
export const CampaignContent = ({ newsLetterForm, thankyouMessage, showThankyouMessage, }) => {
    const { popup } = usePopup();
    if (!popup)
        return <></>;
    return (<div className="grid grid-cols-11 text-sm xs:text-base">
            <div className="col-span-11 bg-white px-2 py-3 xs:col-span-7 xs:min-h-[500px] xs:px-4 xs:py-8 lg:col-span-5">
                {!showThankyouMessage ? (<>
                        <h2 className="font-primary !text-left font-bold">{popup.campaignPopupTitle}</h2>
                        <p>{popup.campaignPopupDescription}</p>
                        {newsLetterForm}
                    </>) : (thankyouMessage)}
            </div>
            <div className="hidden bg-white xs:col-span-4 xs:block lg:col-span-5">
                <NextImage width={1000} height={2000} src={asImageSrc(popup.campaignPopupImageDesktop) || ""} alt="Newsletter alt" quality={100} className="hidden h-full w-full object-cover lg:block"/>
                <NextImage width={1000} height={2000} src={asImageSrc(popup.campaignPopupImageMobile) || asImageSrc(popup.campaignPopupImageDesktop) || ""} alt="Newsletter alt" className="block h-full w-full object-cover lg:hidden" quality={100}/>
            </div>
        </div>);
};
