import { createContext, useContext, useState } from "react";
const SidebarContext = createContext({
    active: false,
    toggle: () => { },
});
export const SidebarProvider = ({ children }) => {
    const [active, setActive] = useState(false);
    return (<SidebarContext.Provider value={{ active, toggle: () => setActive((v) => !v) }}>
            {children}
        </SidebarContext.Provider>);
};
export const useSidebar = () => useContext(SidebarContext);
