import { localStorage, setTwProfilekey } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { v4 } from "uuid";
export const useTwProfileKey = () => {
    const session = useSession();
    useEffect(() => {
        var _a, _b, _c;
        if (session.status === "loading")
            return;
        const key = ((_a = localStorage.getItem("TwRandomKey", { key: "" })) === null || _a === void 0 ? void 0 : _a.key) || v4();
        localStorage.setItem("TwRandomKey", { key });
        setTwProfilekey(((_c = (_b = session.data) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.id) || key);
    }, [session]);
};
