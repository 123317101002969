import { AnimatePresence, motion } from "framer-motion";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
const AddedProductContext = createContext({
    skus: [],
    show: false,
    addedProduct: () => { },
});
export const AddedProductProvider = ({ children, closeTime }) => {
    const [skus, setSkus] = useState([]);
    const timer = useRef();
    useEffect(() => {
        if (skus.length) {
            timer.current = setTimeout(() => {
                setSkus([]);
            }, closeTime);
            return () => {
                timer.current && clearTimeout(timer.current);
            };
        }
    }, [closeTime, skus]);
    const addedProduct = useCallback((sku) => {
        setSkus((v) => [...v, sku]);
    }, []);
    const value = useMemo(() => ({ show: skus.length > 0, skus, addedProduct }), [addedProduct, skus]);
    return <AddedProductContext.Provider value={value}>{children}</AddedProductContext.Provider>;
};
export const useAddedProduct = () => useContext(AddedProductContext);
export const AddedProductPopupAnimation = ({ children, className }) => {
    const { show } = useAddedProduct();
    return (<AnimatePresence>
            {show ? (<motion.div key="addedProduct" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} className={className}>
                    {children}
                </motion.div>) : null}
        </AnimatePresence>);
};
