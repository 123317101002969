import { motion, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
export const HeaderScroll = ({ children, Other }) => {
    const { scrollY } = useScroll();
    const [scroll, setScroll] = useState("down");
    const ref = useRef(null);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        ref.current && setHeight(ref.current.clientHeight);
    }, []);
    useEffect(() => {
        scrollY.on("change", () => {
            const cur = scrollY.get();
            if (cur < height * 1.5) {
                setScroll("down");
                return;
            }
            const prev = scrollY.getPrevious();
            if (cur - prev > 0) {
                setScroll("up");
                return;
            }
            if (prev - cur > 0) {
                setScroll("down");
                return;
            }
        });
        return () => {
            scrollY.clearListeners();
        };
    }, [height, scrollY]);
    return (<>
            {Other ? <Other scroll={scroll}/> : <></>}
            <motion.div ref={ref} variants={{ up: { y: "-100%" }, down: { y: 0 } }} animate={scroll} transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} className="sticky top-0 z-fixed w-full">
                {children}
            </motion.div>
        </>);
};
