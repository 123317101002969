import { CartItem } from "@/components/CartPopup/CartItem";
import { useStores } from "@/stores";
import { CartPopupAnimation, ClientOnly, Link, Money, useI18n } from "@jog/react-components";
import { observer } from "mobx-react";
import { useMemo } from "react";
export const CartPopup = observer(({ show, className }) => {
    var _a, _b, _c;
    const { cartStore } = useStores();
    const lineItems = useMemo(() => cartStore.cartLineItems || [], [cartStore.cartLineItems]);
    const { $t } = useI18n();
    return (<CartPopupAnimation show={show} className={className}>
            <ClientOnly>
                {cartStore.cartTotalItems ? (<div className="w-[336px] bg-white px-4 py-4.5">
                        <h2 className="mb-1.5 text-[19px] font-bold uppercase leading-tight tracking-0.03 text-grey-default">
                            {`${$t("Cart")} (${cartStore.cartLineItemsCount})`}
                        </h2>
                        <ul className="no-scrollbar max-h-[360px] overflow-y-auto">
                            {lineItems.map((item) => (<CartItem key={item.id} item={item}/>))}
                        </ul>
                        <div className="mt-4 border-t border-t-grey-light pt-3">
                            {cartStore.getCartPrices.subTotalPrice && (<div className="flex justify-between text-xs leading-tight tracking-0.03 text-grey-default">
                                    <p>{$t("Subtotal")}</p>
                                    <p>
                                        <Money centAmount={cartStore.getCartPrices.subTotalPrice}/>
                                    </p>
                                </div>)}
                            {cartStore.getCartPrices.totalDiscount > 0 && (<div className="mt-2 flex justify-between text-xs leading-tight tracking-0.03 text-grey-default">
                                    <p>{$t("Discount")}</p>
                                    <p className="text-red-sale">
                                        -<Money centAmount={cartStore.getCartPrices.totalDiscount}/>
                                    </p>
                                </div>)}
                            <div className="mt-2">
                                <div className="flex justify-between text-xs leading-tight tracking-0.03 text-grey-default">
                                    <p>{$t("Shipping costs")}</p>
                                    <p>{$t("Free for JC Friend")}</p>
                                </div>
                            </div>
                            <div className="mt-3 border-t border-t-grey-light pt-3">
                                <div className="flex justify-between leading-tight tracking-0.03 text-grey-default">
                                    <p className="align-bottom">
                                        <span className="text-sm">{$t("Total")}</span>
                                        <span className="ml-1 text-[10px]">{$t("(inc. VAT)")}</span>
                                    </p>
                                    <p className="text-sm font-medium">
                                        {!!((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.total) === null || _c === void 0 ? void 0 : _c.centAmount) && (<Money centAmount={cartStore.cartView.body.total.centAmount}/>)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <Link href="/cart" className="flex w-full items-center justify-center bg-green-main py-3 px-4 text-sm uppercase tracking-0.03 text-white hover:bg-grey-default">
                                {$t("To cart")}
                            </Link>
                        </div>
                    </div>) : (<div className="w-[480px] bg-white p-5 leading-tight tracking-0.03 lg:py-7.5 lg:px-11.5">
                        <h2 className="text-xl capitalize text-black">{$t("YOUR SHOPPING BASKET IS STILL EMPTY")}</h2>
                        <p className="text-sm">{$t("Go shopping! :-)")}</p>
                    </div>)}
            </ClientOnly>
        </CartPopupAnimation>);
});
