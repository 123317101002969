import { Logo } from "@/components";
import { DesktopNavMenu } from "@/components/Header/DesktopNavMenu";
import { NavInfo, Servers } from "@/components/Header/NavInfo";
import { NavSearch } from "@/components/Header/NavSearch";
import { Burger } from "@/components/Icon/Burger";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { useSidebar } from "@jog/react-components";
import { newHeaderEvent } from "@jog/shared";
export const HeaderNav = ({ isCartPage }) => {
    var _a;
    const { toggle } = useSidebar();
    const layout = useLayout();
    const logo = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo;
    const { menu } = newHeaderEvent();
    return (<div className="w-full bg-white">
            <div className="mx-auto w-full max-w-full lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1400px]">
                <div className="grid grid-cols-[auto,1fr,auto] grid-rows-[60px]">
                    <div className="col-start-1 row-start-1 flex items-center justify-center lg:hidden">
                        <button type="button" onClick={() => toggle()}>
                            <Burger className="text-gray-icon"/>
                        </button>
                    </div>
                    <div className="col-start-2 col-end-4 row-start-1 flex items-center justify-start pl-2 lg:col-start-1 lg:justify-center lg:pl-0">
                        <Logo onClick={menu} src={logo.url} width={logo.dimensions.width} height={logo.dimensions.height} alt="Logo"/>
                    </div>
                    <div className="col-start-1 row-start-1 hidden lg:block">
                        <Servers />
                    </div>
                    <div className="col-end-4 row-start-1">
                        <NavInfo isCartPage={isCartPage}/>
                    </div>
                </div>
                <div className="grid grid-cols-[1fr,auto,1fr] grid-rows-[40px]">
                    <div className="col-start-2 col-end-3 row-start-1 hidden lg:block">
                        <DesktopNavMenu />
                    </div>
                    <div className="col-start-1 col-end-4 row-start-1 lg:col-start-3">
                        <NavSearch />
                    </div>
                </div>
            </div>
        </div>);
};
