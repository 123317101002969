import { useI18n, useValidateEmail } from "@jog/react-components";
import { Button } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { Input } from "../../components/InputText/InputText";
const LoginResetPassword = ({ checkEmailForPasswordReset, registeredUserEmail }) => {
    const { $t } = useI18n();
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    const { validateEmail } = useValidateEmail();
    const validateUserEmail = useCallback((e) => {
        setIsErrorShown(!validateEmail(e.target.value));
        setIsButtonDisabled(!validateEmail(e.target.value));
        setUserEmail(e.target.value);
    }, [validateEmail]);
    const submitForm = async () => {
        setIsButtonDisabled(true);
        await checkEmailForPasswordReset(userEmail);
    };
    useEffect(() => {
        if (registeredUserEmail) {
            const e = { target: { value: registeredUserEmail } };
            validateUserEmail(e);
        }
    }, [registeredUserEmail, validateUserEmail]);
    return (<div className="text-base">
            <div className="mb-5">
                {$t("Enter your email address. We will send you a link with which you can reset your password")}
            </div>
            <div>
                <div>
                    <label className="w-full text-base" htmlFor="emailOne">
                        {$t("E-mail address")} *
                    </label>
                </div>
                <div>
                    <Input className="w-full text-base" id="emailOne" onChange={validateUserEmail} type="email" value={userEmail}/>
                    {isErrorShown && <div className="mt-1.25 text-right text-13">{$t("Invalid email address")}</div>}
                </div>
            </div>
            <div className="mt-5 flex justify-end">
                <Button className="!bg-green-main !text-white hover:!bg-orange-main" disabled={isButtonDisabled} onClick={submitForm}>
                    {$t("SEND")}
                </Button>
            </div>
        </div>);
};
export default React.memo(LoginResetPassword);
