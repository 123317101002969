import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { Link, TrustedShopWidget, useNoticeBar } from "@jog/react-components";
import { newHeaderEvent } from "@jog/shared";
import { useMemo } from "react";
export const UspBar = () => {
    var _a;
    const layout = useLayout();
    const headerLinks = useMemo(() => { var _a; return ((_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.headerLinks) || []; }, [(_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.headerLinks]);
    const transform = useNoticeBar(headerLinks.length + 1);
    const style = { ["--y-scroll"]: transform };
    const { usp } = newHeaderEvent();
    return (<div className="relative z-[11] flex h-6 w-full items-center justify-center overflow-hidden bg-grey-default lg:h-auto lg:overflow-visible">
            <div className="block h-6 translate-y-[var(--y-scroll)] no-underline transition-transform duration-300 ease-out lg:flex lg:h-auto lg:translate-y-0 lg:space-x-10 lg:py-2" style={style}>
                <div className="flex h-6 items-center justify-center lg:h-auto">
                    <TrustedShopWidget className="pointer-events-none scale-75 text-sm lg:pointer-events-auto lg:-m-3.75 lg:scale-100 lg:text-xs"/>
                </div>
                {headerLinks === null || headerLinks === void 0 ? void 0 : headerLinks.map((item, index) => (<div className="flex h-6 items-center justify-center lg:h-auto" key={index}>
                        <Link to={item.link} className="uspIcon truncate text-xs uppercase text-white no-underline" onClick={() => usp()}>
                            {item.label}
                        </Link>
                    </div>))}
            </div>
        </div>);
};
