export const Trash = (props) => (<svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 128.00 128.00" {...props}>
        <path fill="white" d="
      M 52.10 0.00
      L 76.96 0.00
      C 83.89 2.62 82.17 8.58 82.40 14.26
      Q 82.43 14.82 82.99 14.82
      Q 93.07 14.75 105.70 14.71
      C 113.34 14.68 114.10 21.73 116.08 27.21
      C 117.97 32.43 115.46 37.25 109.53 36.94
      Q 108.81 36.91 108.75 37.63
      Q 105.62 75.94 102.59 114.61
      C 102.04 121.67 99.61 126.21 92.60 128.00
      L 37.53 128.00
      Q 28.44 125.61 27.77 117.25
      Q 24.58 77.24 21.30 37.27
      A 0.44 0.44 0.0 0 0 20.78 36.87
      C 15.45 37.87 10.71 34.13 12.39 28.56
      Q 13.56 24.66 15.07 20.31
      C 16.23 16.98 19.07 14.75 22.61 14.74
      Q 35.39 14.71 45.94 14.81
      A 0.50 0.50 0.0 0 0 46.44 14.34
      C 46.73 8.64 44.80 2.28 52.10 0.00
      Z"/>
        <path fill="currentcolor" d="
      M 52.10 0.00
      L 76.96 0.00
      C 83.89 2.62 82.17 8.58 82.40 14.26
      Q 82.43 14.82 82.99 14.82
      Q 93.07 14.75 105.70 14.71
      C 113.34 14.68 114.10 21.73 116.08 27.21
      C 117.97 32.43 115.46 37.25 109.53 36.94
      Q 108.81 36.91 108.75 37.63
      Q 105.62 75.94 102.59 114.61
      C 102.04 121.67 99.61 126.21 92.60 128.00
      L 37.53 128.00
      Q 28.44 125.61 27.77 117.25
      Q 24.58 77.24 21.30 37.27
      A 0.44 0.44 0.0 0 0 20.78 36.87
      C 15.45 37.87 10.71 34.13 12.39 28.56
      Q 13.56 24.66 15.07 20.31
      C 16.23 16.98 19.07 14.75 22.61 14.74
      Q 35.39 14.71 45.94 14.81
      A 0.50 0.50 0.0 0 0 46.44 14.34
      C 46.73 8.64 44.80 2.28 52.10 0.00
      Z
      M 74.74 7.93
      A 0.44 0.44 0.0 0 0 74.30 7.49
      L 54.48 7.49
      A 0.44 0.44 0.0 0 0 54.04 7.93
      L 54.04 14.33
      A 0.44 0.44 0.0 0 0 54.48 14.77
      L 74.30 14.77
      A 0.44 0.44 0.0 0 0 74.74 14.33
      L 74.74 7.93
      Z
      M 108.42 29.48
      A 0.33 0.33 0.0 0 0 108.74 29.04
      L 106.44 22.47
      A 0.33 0.33 0.0 0 0 106.13 22.25
      L 22.69 22.25
      A 0.33 0.33 0.0 0 0 22.38 22.47
      L 20.07 29.04
      A 0.33 0.33 0.0 0 0 20.38 29.48
      L 108.42 29.48
      Z
      M 94.75 116.90
      L 101.19 37.49
      Q 101.23 37.00 100.74 37.00
      L 29.45 37.00
      Q 28.88 37.00 28.93 37.56
      L 35.34 116.71
      A 4.13 4.13 0.0 0 0 39.46 120.50
      L 90.85 120.50
      A 3.91 3.91 0.0 0 0 94.75 116.90
      Z"/>
        <rect fill="currentcolor" x="-3.8" y="-37.6" transform="translate(44,80) rotate(-3)" width="8" height="75" rx="3.73"/>
        <rect fill="currentcolor" x="-3.8" y="-37.6" transform="translate(64,80) " width="8" height="75" rx="3.75"/>
        <rect fill="currentcolor" x="-3.8" y="-37.6" transform="translate(84,80) rotate(3)" width="8" height="75" rx="3.73"/>
    </svg>);
