import { useI18n } from "@jog/react-components";
import { getBrowserName } from "@jog/shared";
import { Modal } from "components";
import React from "react";
const DisableLocationModal = ({ hideModal }) => {
    const browserName = getBrowserName();
    const { $t } = useI18n();
    let modalContent;
    switch (browserName) {
        case "safari":
            modalContent = (<div>
                    <p>{$t("To stop sharing your location follow this steps")}:</p>
                    <ol>
                        <li>{$t('Go to Safari "Preferences"')}</li>
                        <li>{$t('On the "Website" tab find the "Location" section')}</li>
                        <li>{$t("Select JeansCentre and update your preferences")}</li>
                    </ol>
                </div>);
            break;
        case "chrome":
        case "firefox":
            modalContent = (<div>
                    <p>{$t("To stop sharing your location follow this steps")}:</p>
                    <ol>
                        <li>{$t("Click on the padlock icon on top of your browser, next to the URL")}</li>
                        <li>{$t("Update the Location configuration from the dropdown")}</li>
                        <li>
                            {$t('To re-enable this feature you can click on the "Enable Location Sharing" button in your profile preferences')}
                        </li>
                    </ol>
                </div>);
            break;
        default:
            modalContent = (<div>
                    <p>{$t("To stop sharing your location follow this steps")}:</p>
                    <ol>
                        <li>{$t("Go to your browser settings")}</li>
                        <li>{$t('Under "Permissions" you can update the Location sharing feature')}</li>
                        <li>
                            {$t('To re-enable this feature you can click on the "Enable Location Sharing" button in your profile preferences')}
                        </li>
                    </ol>
                </div>);
            break;
    }
    return (<Modal hideModal={hideModal} headerTitle={$t("Disable location sharing")}>
            {modalContent}
        </Modal>);
};
export default React.memo(DisableLocationModal);
