import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { HeaderScroll } from "@jog/react-components";
import { newHeaderEvent } from "@jog/shared";
import { Logo } from "components";
import { MobileNav } from "./MobileNav";
import { HeaderNav } from "./Nav";
import { UspBar } from "./UspBar";
const CheckoutHeader = () => {
    var _a;
    const layout = useLayout();
    const logo = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo;
    const { menu } = newHeaderEvent();
    return (<div className="w-full bg-white">
            <div className="flex h-20 w-full items-center justify-center">
                {!!(logo === null || logo === void 0 ? void 0 : logo.url) && (<Logo onClick={menu} src={logo.url} width={logo.dimensions.width} height={logo.dimensions.height} alt="Logo" href="/cart"/>)}
            </div>
        </div>);
};
export const Header = ({ isCheckoutPage, isCartPage }) => {
    if (isCheckoutPage) {
        return (<HeaderScroll>
                <CheckoutHeader />
            </HeaderScroll>);
    }
    return (<HeaderScroll>
            <UspBar />
            <HeaderNav isCartPage={isCartPage}/>
            <MobileNav />
        </HeaderScroll>);
};
