import { autocomplete, sleep } from "@jog/shared";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
export const useNavSearch = ({ itemLength, suggestionLength }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchShown, setIsSearchShown] = useState(false);
    const [items, setItems] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const onSearch = useCallback(async () => {
        if (searchTerm.trim().length > 2) {
            const searchResults = await autocomplete(searchTerm)
                .then((res) => res.body)
                .catch(() => ({ items: [], suggestions: [] }));
            setItems(searchResults.items.slice(0, itemLength));
            setSuggestions(searchResults.suggestions.slice(0, suggestionLength));
            setIsSearchShown(true);
        }
        else {
            setIsSearchShown(false);
            setItems([]);
            setSuggestions([]);
        }
    }, [itemLength, searchTerm, suggestionLength]);
    const timer = useRef(null);
    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(async () => {
            await onSearch();
        }, 300);
        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, [onSearch]);
    const onBlur = useCallback(async () => {
        await sleep(500);
        setIsSearchShown(false);
    }, []);
    const router = useRouter();
    const handleSearch = useCallback(async (searchTermSuggestion = "") => {
        const _searchTerm = searchTermSuggestion || searchTerm;
        if (_searchTerm) {
            setIsSearchShown(false);
            if (router.pathname === "/search") {
                await router.replace({ pathname: "/search", query: { tn_q: _searchTerm } });
            }
            else {
                await router.push({ pathname: "/search", query: { tn_q: _searchTerm } });
            }
        }
    }, [router, searchTerm]);
    const onFocus = useCallback(() => {
        if (searchTerm.trim().length > 2 && (suggestions.length > 0 || items.length > 0)) {
            setIsSearchShown(true);
        }
    }, [items.length, searchTerm, suggestions.length]);
    return { handleSearch, onFocus, onBlur, isSearchShown, items, suggestions, searchTerm, setSearchTerm };
};
