import { getGiftcard } from "@/lib/jog";
import { useGiftcard, useI18n } from "@jog/react-components";
import classNames from "classnames";
import { AlertBox, ArrowButton, Container, Input, LogoLoader } from "components";
export const GiftcardContainer = () => {
    const { $t } = useI18n();
    const { pending, value, isGiftcardError, recaptchaErrorMessage, setInputVal, onSubmit } = useGiftcard(getGiftcard);
    return (<div className="text-base lg:px-4">
            {pending && <LogoLoader />}
            <Container className="mb-17.5">
                <div className="mb-7.5 text-[22px] uppercase">
                    <span className="bold">{$t("give and surprise")}</span> {$t("check the balance of your gift card")}
                </div>
                <div>
                    {$t("The balance on the JC Gift Card is valid indefinitely in all JC Stores and on JC.nl. Enter the card number and immediately know how much you can spend at Jeans Center. The number can be found on the back of the card.")}
                </div>
                <form className="mt-7.5" onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
        }}>
                    <div className="grid w-full grid-cols-12">
                        <div className="col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-2">
                            <label className="mt-2.5 whitespace-nowrap" htmlFor="giftcardInput">
                                {$t("Code of your gift card")}: *
                            </label>
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4">
                            <Input className={classNames("mt-2.5 h-11 w-full border md:mt-0", isGiftcardError ? "text-red-error" : "text-gray-medium")} type="text" onChange={(e) => setInputVal(e.target.value)} id="giftcardInput"/>
                            {isGiftcardError && (<div className="text-red-error">
                                    {recaptchaErrorMessage
                ? recaptchaErrorMessage
                : $t("The code entered is invalid or the balance is € 0.-")}
                                </div>)}
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <ArrowButton className="mt-2.5 w-full md:mt-0 md:w-auto" type="submit">
                                {$t("Check your balance")}
                            </ArrowButton>
                        </div>
                        <div className="col-span-12 mt-2.5 md:col-span-9 md:col-start-3 md:mt-5 xl:col-span-10 xl:col-start-2"></div>
                    </div>
                </form>
                {value && (<div className="flex pr-4">
                        <div className="w-full basis-full md:basis-9/12 lg:basis-8/12 xl:basis-6/12">
                            <AlertBox className="!text-gray-dracula" text={`${$t("Your balance is")}: € ${value}`}/>
                        </div>
                    </div>)}
            </Container>
        </div>);
};
