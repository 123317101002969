import { Link, useI18n } from "@jog/react-components";
import { Section } from "components";
export const AccountPrivacy = ({ privacyLinks }) => {
    const { $t } = useI18n();
    return (<Section id="privacy-section" title={$t("Privacy")}>
            <nav className="flex flex-col flex-nowrap">
                <ul>
                    {privacyLinks.map((item, index) => (<li className="mb-2.5" key={index}>
                            <Link to={item.link} className="font-normal uppercase text-gray-shuttle">
                                {$t(item.title)}
                            </Link>
                        </li>))}
                </ul>
            </nav>
        </Section>);
};
