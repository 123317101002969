import { asText } from "@prismicio/helpers";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
export const transPopup = (data) => {
    const renderTrustedShops = data.render_trsusted_shops;
    const activatePopupCampaign = data.activate_popup_campaign;
    const campaignBlacklist = (data.campaignpopup_blacklist || "").split(",");
    const campaignPopupTitle = asText(data.campaignpopup_title);
    const campaignPopupDescription = asText(data.campaignpopup_description);
    const campaignPopupButtonText = asText(data.campaignpopup_button_text);
    const campaignPopupOptionsText = asText(data.campaignpopup_options_text);
    const campaignPopupActivateFilterOptions = data.campaignpopup_activate_filter_options;
    const campaignPopupButtonBgColor = data.campaignpopup_button_bgcolor || "";
    const campaignPopupButtonColor = data.campaignpopup_button_color || "";
    const campaignCancelText = data.campaignpopup_cancel_text || "";
    const closeIconBgColor = data.close_icon_bgcolor || "";
    const closeIconColor = data.close_icon_color || "";
    const campaignPopupOptionsList = [...(data.campaignpopup_options || [])].map((option) => ({
        key: option.campaignpopup_option_key || "",
        value: asText(option.campaignpopup_option_value) || "",
    }));
    const campaignPopupImageDesktop = data.campaignpopup_image_desktop;
    const campaignPopupImageMobile = data.campaignpopup_image_mobile;
    const campaignPopupThankyouTitle = asText(data.campaignpopup_thankyou_title) || "";
    const campaignPopupThankyouBody = data.campaignpopup_thankyou_body;
    const campaignPopupDelay = data.campaignpopup_delay || 0;
    return {
        renderTrustedShops,
        activatePopupCampaign,
        campaignBlacklist,
        campaignPopupTitle,
        campaignPopupDescription,
        campaignPopupButtonText,
        campaignPopupOptionsText,
        campaignPopupActivateFilterOptions,
        campaignPopupOptionsList,
        campaignPopupImageDesktop,
        campaignPopupImageMobile,
        campaignPopupThankyouTitle,
        campaignPopupThankyouBody,
        campaignPopupDelay,
        campaignPopupButtonBgColor,
        campaignPopupButtonColor,
        campaignCancelText,
        closeIconBgColor,
        closeIconColor,
    };
};
export const PopupContext = createContext({
    popup: null,
    error: false,
});
export const PopupProvider = ({ children, fetch, }) => {
    const [popup, setPopup] = useState(null);
    const [error, setError] = useState(false);
    const init = useCallback(async () => {
        if (popup)
            return;
        try {
            const data = await fetch();
            setPopup(data);
            setError(false);
        }
        catch (e) {
            console.log(e);
            setPopup(null);
            setError(true);
        }
    }, [fetch, popup]);
    useEffect(() => {
        init();
    }, [init]);
    return <PopupContext.Provider value={{ popup, error }}>{children}</PopupContext.Provider>;
};
export const usePopup = () => useContext(PopupContext);
