import { getMemberJcPoints } from "@/lib/jog";
import { NextImage, useI18n } from "@jog/react-components";
import { onlyDigitsWithExactLength } from "@jog/shared";
import { FormikFormWrapper } from "@jog/theming";
import { Button } from "components/Button/Button";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
const JcPointsForm = () => {
    const { $t } = useI18n();
    const [jcPointsBalance, setJcPointsBalance] = useState(null);
    return (<div className="text-base [&_input]:placeholder:text-gray-silver">
            <Formik initialValues={{ cartNumber: "", securityCode: "" }} validate={(values) => {
            if (!onlyDigitsWithExactLength(values.cartNumber, 13))
                return { cartNumber: true };
            if (!onlyDigitsWithExactLength(values.securityCode, 4))
                return { securityCode: true };
            return {};
        }} onSubmit={async (values) => {
            const response = await getMemberJcPoints(values.cartNumber);
            if (response && response.statusCode === 200) {
                setJcPointsBalance(response.body.balance);
            }
        }}>
                {({ isSubmitting, isValid, dirty }) => (<Form>
                        <FormikFormWrapper className="mt-7.5">
                            <div className="grid grid-cols-12">
                                <div className="order-2 col-span-12 flex flex-col md:order-1 md:col-span-5 lg:col-span-4">
                                    {!jcPointsBalance ? (<>
                                            <Field type="number" name="cartNumber" id="cartNumber" className="mb-5" placeholder={$t("Card number (13 digits)")}/>
                                            <Field type="number" name="securityCode" id="securityCode" className="mb-5" placeholder={$t("Secure code (4 digits)")}/>
                                            <Button className="w-full hover:!bg-orange-main" bgColor="#809A7B" color="#fff" type="submit" disabled={!isValid || !dirty || isSubmitting}>
                                                {$t("Requesting point balance")}
                                            </Button>
                                        </>) : (<div className="flex h-[190px] flex-wrap items-center justify-center bg-green-main text-center font-primary text-[19px] font-bold text-white lg:w-[400px]">
                                            <div>
                                                <div className="mb-2.5">{$t("Your item balance is")}</div>
                                                {jcPointsBalance ? (<>
                                                        <div>{$t("Incorect code")}</div>
                                                        <div>
                                                            {jcPointsBalance} {$t("Wells")}
                                                        </div>
                                                    </>) : null}
                                            </div>
                                        </div>)}
                                </div>
                                <div className="order-1 col-span-12 mb-7.5 md:order-2 md:col-span-6 md:col-start-7 md:mb-0 lg:col-span-7 lg:col-start-6">
                                    <NextImage width={300} height={200} alt="jc-friend" className="hidden h-[220px] w-auto object-contain lg:block" src="/images/jcfriends.png"/>
                                    <NextImage width={300} height={200} alt="jc-friend" className="block h-[220px] w-full object-contain lg:hidden" src="/images/jcfriends-sm.jpg"/>
                                </div>
                            </div>
                        </FormikFormWrapper>
                    </Form>)}
            </Formik>
        </div>);
};
export default JcPointsForm;
