import { Close, Logo } from "@/components";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { Arrow, Link, MobileNavContainer, signOutUser, useI18n, useMobileNav, useSidebar } from "@jog/react-components";
import { asImageSrc, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { useSession } from "next-auth/react";
import { useMemo } from "react";
const NavItem = ({ categoryName, categoryColor, categoryLink, labelBgColor, labelColor, labelName, hasBottomBorder, isArrowShow, onClickFunc, }) => (<li className={classNames("cursor-pointer text-gray-base", {
        "mb-2 border-b border-b-gray-silver pb-2": hasBottomBorder,
    })}>
        <Link className="flex w-full items-center justify-between px-5.5 py-2.5 no-underline hover:no-underline" style={{ color: categoryColor }} to={categoryLink} onClick={onClickFunc}>
            <div className="flex items-center">
                <div className="mb-0" dangerouslySetInnerHTML={{ __html: categoryName }}></div>
                <span className="ml-2 flex items-center justify-center px-1 font-sans text-0.85 text-gray-base" style={{
        color: labelColor !== null && labelColor !== void 0 ? labelColor : "#fff",
        backgroundColor: labelBgColor !== null && labelBgColor !== void 0 ? labelBgColor : "red",
    }}>
                    {labelName}
                </span>
            </div>
            {isArrowShow ? <Arrow color={categoryColor}/> : null}
        </Link>
    </li>);
const BackNavItem = ({ onClick, children }) => {
    return (<li className="relative flex cursor-pointer items-center justify-center border-b border-b-gray-silver px-5.5 py-2.5 text-center font-primary text-lg font-medium text-gray-base" onClick={onClick}>
            <span className="absolute left-5.5 h-2.5 w-2.5 rotate-45 transform border-l-2 border-b-2 border-gray-base"></span>
            {children}
        </li>);
};
export const MobileNav = () => {
    var _a, _b, _c;
    const layout = useLayout();
    const { active } = useSidebar();
    const { mainMenuShow, subMenuShow, hideMenu, subMenus, setSubMenus, thirdMenus, setThirdMenus, activeMainMenu, setActiveMainMenu, activeSubMenu, setActiveSubMenu, } = useMobileNav();
    const { $t } = useI18n();
    const session = useSession();
    const isUserLoggedIn = useMemo(() => session.status == "authenticated", [session]);
    const logo = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo;
    return (<MobileNavContainer>
            <div className="col-span-10 bg-white text-15">
                <div className="border-b border-gray-silver py-5.75">
                    {isFilled.image(logo) && (<Logo src={asImageSrc(logo)} width={225} height={28} alt={logo.alt || "Logo"}/>)}
                </div>
                <div className="max-h-sideNav overflow-auto overscroll-y-contain">
                    {mainMenuShow ? (<ul>
                            {(_c = (_b = layout === null || layout === void 0 ? void 0 : layout.nav) === null || _b === void 0 ? void 0 : _b.mobile) === null || _c === void 0 ? void 0 : _c.map((main) => {
                var _a;
                return (<NavItem categoryName={main.categoryName} categoryColor={main.categoryTextColor || ""} categoryLink={main.categoryUrl} hasBottomBorder={main.hasBottomBorder} labelBgColor={main.labelBgColor || ""} labelColor={main.labelTextColor || ""} labelName={main.labelText} isArrowShow={!!((_a = main.subCategoryItems) === null || _a === void 0 ? void 0 : _a.length)} onClickFunc={(e) => {
                        if (main.subCategoryItems.length > 0) {
                            e.preventDefault();
                            e.stopPropagation();
                            setSubMenus(main.subCategoryItems);
                            setActiveMainMenu(main.categoryNameString);
                        }
                        else {
                            hideMenu();
                        }
                    }} key={main.categoryName}/>);
            })}
                            <li className="px-5.5 py-2.5">
                                {isUserLoggedIn ? (<>
                                        <Link className="w-full no-underline hover:no-underline" to="/my-account" onClick={hideMenu}>
                                            <span className="lowercase text-gray-base">{$t("My account")}</span>
                                        </Link>
                                        <span className="block w-full py-2.5 text-gray-base hover:text-red-mona" onClick={() => {
                    signOutUser();
                    hideMenu();
                }}>
                                            {$t("Log out")}
                                        </span>
                                    </>) : (<Link className="w-full no-underline hover:no-underline" to="/login" onClick={hideMenu}>
                                        <span className="text-gray-base">{$t("Log in / register")}</span>
                                    </Link>)}
                            </li>
                        </ul>) : null}
                    {subMenuShow ? (<ul>
                            <BackNavItem onClick={() => {
                setSubMenus([]);
                setActiveMainMenu("");
            }}>
                                {activeMainMenu}
                            </BackNavItem>
                            {subMenus.map((sub, subIndex) => {
                var _a;
                return (<NavItem categoryName={sub.subCategoryName} categoryColor={sub.subCategoryTextColor || ""} categoryLink={sub.subCategoryUrl} labelBgColor={sub.subLabelBgColor || ""} labelColor={sub.subLabelTextColor || ""} labelName={sub.subLabelText} hasBottomBorder={!!sub.hasBottomBorder} isArrowShow={!!((_a = sub.thirdCategoryItems) === null || _a === void 0 ? void 0 : _a.length)} onClickFunc={(e) => {
                        if (sub.thirdCategoryItems.length > 0) {
                            e.stopPropagation();
                            e.preventDefault();
                            setThirdMenus(sub.thirdCategoryItems);
                            setActiveSubMenu(sub.subCategoryNameString);
                        }
                        else {
                            hideMenu();
                        }
                    }} key={sub.subCategoryName + subIndex}/>);
            })}
                        </ul>) : null}
                    {thirdMenus.length ? (<ul>
                            <BackNavItem onClick={() => {
                setThirdMenus([]);
                setActiveSubMenu("");
            }}>
                                {activeSubMenu}
                            </BackNavItem>
                            {thirdMenus.map((third, thirdIndex) => (<NavItem categoryName={third.thirdCategoryName} categoryColor={third.thirdCategoryTextColor || ""} categoryLink={third.thirdCategoryUrl} labelBgColor={third.thirdLabelBgColor || ""} labelColor={third.thirdLabelTextColor || ""} labelName={third.thirdLabelText} hasBottomBorder={!!third.hasBottomBorder} onClickFunc={hideMenu} key={third.thirdCategoryName + thirdIndex}/>))}
                        </ul>) : null}
                </div>
            </div>
            <div className={classNames("col-span-2 bg-black/50", active ? "block" : "hidden")} onClick={hideMenu}>
                <Close fontSize="34px"/>
            </div>
        </MobileNavContainer>);
};
