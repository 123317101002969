import { useEffect, useState } from "react";
import { useI18n } from "../../hooks";
export const ReturnThankyouBase = ({ ContentLoader, AlertBox, RichText, getReturnOrder, getOrdersDetail, }) => {
    const Loading = () => (<>
            {Array(12)
            .fill(1)
            .map((_, index) => (<ContentLoader width="100%" height="40px" key={index}/>))}
        </>);
    const ReturnThankyouContent = ({ prismicContent, orderNumber, email, }) => {
        const { $t } = useI18n();
        const [orderFromCT, setOrderFromCT] = useState(null);
        const [orderFromRU, setOrderFromRU] = useState(null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState("");
        useEffect(() => {
            setLoading(true);
            Promise.all([getReturnOrder(orderNumber, email), getOrdersDetail([orderNumber])])
                .then((res) => {
                var _a, _b, _c, _d;
                setOrderFromRU((_b = (_a = res[0]) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.order);
                if ((_c = res[1]) === null || _c === void 0 ? void 0 : _c[orderNumber]) {
                    setOrderFromCT((_d = res[1]) === null || _d === void 0 ? void 0 : _d[orderNumber]);
                }
                else {
                    console.error("No orders found");
                    setError("No orders found");
                }
            })
                .catch((e) => {
                console.error(e);
                setError("No orders found");
            })
                .finally(() => {
                setLoading(false);
            });
        }, [email, orderNumber]);
        // const products = useMemo(
        //     () =>
        //         (orderFromRU?.line_details?.line?.filter(
        //             (line) => line.type === "PRODUCT" && line.barcode !== JC_FRIEND_SKU
        //         ) as ProductLine[]) || [],
        //     [orderFromRU]
        // )
        return error ? (<AlertBox isError text={$t(error)}/>) : loading ? (<Loading />) : orderFromCT && orderFromRU ? (<div className="w-full bg-white p-5">
                <div className="mb-5">
                    <RichText field={prismicContent.thank_you_title}/>
                </div>
                <div className="mb-3">
                    <RichText field={prismicContent.thank_you_desc} linkTarget="_blank"/>
                </div>
                <div className="mb-3 w-full bg-gray-bg px-4 py-4 text-center lg:px-60">
                    <RichText field={prismicContent.thank_you_content} linkTarget="_blank"/>
                </div>
                <div>
                    <RichText field={prismicContent.thank_you_question} linkTarget="_blank"/>
                </div>
            </div>) : null;
    };
    return ReturnThankyouContent;
};
